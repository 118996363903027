<template>
  <div v-if="visible" class="copy-modal">
    <div class="text-wrapper">
      <span class="txt-copy">Copied: &nbsp;</span> {{ copyText }}
    </div>
  </div>
</template>
<script setup lang="ts">
  // Properties
  const visible: Ref<boolean> = ref(false)
  const copyText: Ref<string> = ref('')
  let timeOut: any = null

  // Methods and Event handlers
  const show = (value: string) => {
    visible.value = false
    clearTimeout(timeOut)
    copyText.value = value
    visible.value = true
    timeOut = setTimeout(() => {
      visible.value = false
    }, 5000)
  }

  const hide = () => {
    copyText.value = ''
    visible.value = false
  }

  // Methods exposed to parent components
  defineExpose({
    show,
    hide,
  })
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/_color-palette.scss';
  .copy-modal {
    position: absolute;
    background-color: $white;
    left: 6.5rem;
    bottom: 3rem;
    border-radius: 0.5rem;
    padding: 1.25rem;
    @include font-style(12px, 500);
    z-index: 9999;
    .txt-copy {
      opacity: 0.7;
    }
  }
</style>
